import { IconName } from "@blueprintjs/core";
import React, { useMemo } from "react";

import { TERMINOLOGY } from "../../hooks/useTerminologyText";
import { ClientCounterEvent } from "../../util/clientCounterEvent";
import { CyData } from "../../util/cypress";
import {
  DataSourcesIcon,
  EnvironmentIcon,
  FilesIcon,
  HelpIcon,
  HistoryIcon,
  OutlineIcon,
  ProjectSearchIcon,
  ScheduledRunsSidebarIcon,
  VariablesIcon,
} from "../icons/CustomIcons";

export const DEFAULT_SIDEBAR_WIDTH = 341;
export const COLLAPSED_SIDEBAR_WIDTH = 48;
export const MAX_SIDEBAR_WIDTH = 600;

export enum SidebarTab {
  OUTLINE = "outline",
  DATA_CONNECTIONS = "data-connections",
  ENVIRONMENT = "environment",
  FILES = "files",
  VARIABLES = "variables",
  SCHEDULED_RUNS = "scheduled-runs",
  HISTORY = "history",
  SEARCH = "search",
  LEARN = "learn",
}

export interface SidebarTabData {
  icon: IconName | JSX.Element;
  slug: SidebarTab;
  title: string;
  counterEvent?: ClientCounterEvent;
  cyData?: string;
  // optionally set if the title is different in the component view
  componentsTitle?: string;
  location?: "top" | "bottom-upper" | "bottom-lower";
}

export const useSidebarTabInfo: () => Record<
  SidebarTab,
  SidebarTabData
> = () => {
  return useMemo(
    () => ({
      [SidebarTab.OUTLINE]: {
        icon: <OutlineIcon />,
        slug: SidebarTab.OUTLINE,
        title: "Outline",
        cyData: CyData.OUTLINE_TAB,
        counterEvent: "sidebar.click_tab.outline",
      },
      [SidebarTab.SEARCH]: {
        icon: <ProjectSearchIcon />,
        slug: SidebarTab.SEARCH,
        title: "Find",
        counterEvent: "sidebar.click_tab.search",
      },
      [SidebarTab.DATA_CONNECTIONS]: {
        icon: <DataSourcesIcon />,
        slug: SidebarTab.DATA_CONNECTIONS,
        cyData: CyData.DATA_CONNECTION_TAB,
        title: TERMINOLOGY.dataBrowserText,
        counterEvent: "sidebar.click_tab.data_browser",
      },
      [SidebarTab.ENVIRONMENT]: {
        icon: <EnvironmentIcon />,
        slug: SidebarTab.ENVIRONMENT,
        title: "Environment",
        counterEvent: "sidebar.click_tab.environment",
      },
      [SidebarTab.FILES]: {
        icon: <FilesIcon />,
        slug: SidebarTab.FILES,
        title: "Files",
        counterEvent: "sidebar.click_tab.files",
      },
      [SidebarTab.VARIABLES]: {
        icon: <VariablesIcon />,
        slug: SidebarTab.VARIABLES,
        cyData: CyData.VARIABLES_TAB,
        title: "Variables",
        counterEvent: "sidebar.click_tab.variables",
      },
      [SidebarTab.SCHEDULED_RUNS]: {
        icon: <ScheduledRunsSidebarIcon />,
        slug: SidebarTab.SCHEDULED_RUNS,
        title: "Scheduled runs",
        cyData: CyData.SCHEDULED_RUNS_SIDEBAR_TAB,
        counterEvent: "sidebar.click_tab.scheduled_runs",
      },
      [SidebarTab.HISTORY]: {
        icon: <HistoryIcon />,
        slug: SidebarTab.HISTORY,
        cyData: CyData.VERSION_TAB,
        title: "History & versions",
        counterEvent: "sidebar.click_tab.history_versions",
      },
      [SidebarTab.LEARN]: {
        icon: <HelpIcon />,
        slug: SidebarTab.LEARN,
        title: "Help & support",
        location: "bottom-lower",
        cyData: CyData.HELP_AND_SUPPORT,
        counterEvent: "sidebar.click_tab.help",
      },
    }),
    [],
  );
};
