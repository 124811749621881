import {
  CellType,
  DataSourceTableConfig,
  DataSourceTableId,
  DisplayTableColumnId,
  ExploreCellDataframe,
  SemanticDatasetInput,
  SemanticDatasetName,
  SemanticDatasetStub,
  SemanticProjectId,
  SharedFilterAutoLinks,
  SharedFilterManualLinks,
  SharedFilterPredicateOp,
  buildParameterFilter,
  getDataframeName,
  getJoinTreeForExplore,
  getSemanticDatasetTree,
  isFilterableCellType as isFilterableCellTypeInternal,
  parseJsonSerializedParameterValueContents,
} from "@hex/common";

import {
  CellContentsMP,
  CellMP,
} from "../../redux/slices/hexVersionMPSlice.js";
import { getDataFramesForCell } from "../cell/utils/getDataframesForCell.js";

import { SharedFilter } from "./types.js";

/**
 * This only returns dataframes that are relevant for shared filters
 */
export function getSharedFilterableDataFramesForCell(
  cellContents: CellContentsMP,
): readonly ExploreCellDataframe[] {
  if (cellContents.__typename === "SqlCell") {
    return [cellContents.resultVariable];
  }

  if (isFilterableCellType(cellContents.__typename)) {
    return getDataFramesForCell(cellContents);
  }
  return [];
}

/**
 * This only returns dataframe names that are relevant for shared filters
 */
export function getSharedFilterableDataFrameNamesForCell(
  cellContents: CellContentsMP,
): readonly string[] {
  return getSharedFilterableDataFramesForCell(cellContents).map((df) =>
    getDataframeName(df),
  );
}

export function getSharedFilterableTablesForCell(
  cellContents: CellContentsMP,
): DataSourceTableId[] {
  const inputTable = getDataFramesForCell(cellContents)
    .filter((df) => DataSourceTableConfig.guard(df))
    .map((table) => table.dataSourceTableId);

  // if a explore cell doesn't have a table input, then it won't have any joined tables (for now).
  if (cellContents.__typename !== "ExploreCell" || inputTable.length === 0) {
    return inputTable;
  }

  const joinedTables =
    cellContents.spec.tables?.map((table) => table.dataSourceTableId) ?? [];
  return [...inputTable, ...joinedTables];
}

export function isSharedFilterConfigured(sf: {
  autoLinks: SharedFilterAutoLinks | null;
  manualLinks: SharedFilterManualLinks | null;
  operator: SharedFilterPredicateOp | null;
}): boolean {
  return (
    sf.operator != null &&
    ((sf.autoLinks != null && sf.autoLinks.length > 0) ||
      (sf.manualLinks != null &&
        sf.manualLinks.filter((ml) => !ml.isExclude).length > 0))
  );
}

export function isSharedFilterActive(sf: SharedFilter): boolean {
  if (!isSharedFilterConfigured(sf) || sf.value == null) {
    return false;
  }

  return (
    buildParameterFilter({
      column: DisplayTableColumnId.check(sf.name ?? ""),
      operator: sf.operator,
      value: parseJsonSerializedParameterValueContents(sf.value),
    }) != null
  );
}

export function isFilterableCellType(
  type: CellContentsMP["__typename"] | CellMP["cellType"],
): boolean {
  return (
    type === "ChartCell" ||
    type === "DisplayTableCell" ||
    type === "SqlCell" ||
    type === "PivotCell" ||
    type === "ExploreCell" ||
    isFilterableCellTypeInternal(type as CellType)
  );
}

export const getDatasetQueryPathForCell = ({
  cellContents,
  datasetName,
  semanticDatasets,
  semanticProjectId,
}: {
  cellContents: CellContentsMP;
  datasetName: SemanticDatasetName;
  semanticProjectId: SemanticProjectId;
  semanticDatasets: readonly SemanticDatasetStub[];
}): SemanticDatasetName[] | undefined => {
  const maybeSemanticInput =
    getSharedFilterableDataFramesForCell(cellContents)[0];
  if (
    cellContents.__typename !== "ExploreCell" ||
    !SemanticDatasetInput.guard(maybeSemanticInput)
  ) {
    // semantic datasets can only be joined into explores with semantic inputs
    return undefined;
  }

  if (cellContents.semanticProjectId !== semanticProjectId) {
    // don't cross filter across semantic projects
    return undefined;
  }

  if (maybeSemanticInput.semanticDatasetName === datasetName) {
    // cell has same base dataset
    return [];
  }

  // we only care about root nodes - if there is an ambigous join path to the
  // dataset, then we ignore it
  const { rootNodes } = getSemanticDatasetTree(
    maybeSemanticInput.semanticDatasetName,
    semanticDatasets,
  );

  return rootNodes.find((node) => node.name === datasetName)?.pathToNode;
};

export const getTableQueryPathForCell = ({
  cellContents,
  tableId,
  tableName,
}: {
  cellContents: CellContentsMP;
  tableId: DataSourceTableId;
  tableName: string;
}): SemanticDatasetName[] | undefined => {
  const maybeWhTable = getSharedFilterableDataFramesForCell(cellContents)[0];
  if (!DataSourceTableConfig.guard(maybeWhTable)) {
    // joins can only be configured for explores with WH table inputs for now -
    // so input is not a WH table we can return early.
    return undefined;
  }
  if (maybeWhTable.dataSourceTableId === tableId) {
    // cell has same base table
    return [];
  }

  if (
    cellContents.__typename !== "ExploreCell" ||
    cellContents.spec.joins == null ||
    cellContents.spec.tables == null
  ) {
    // if no joins configured (and not the base table, which we check above),
    // then this table isn't referenced in the cell
    return undefined;
  }

  // we only care if the table is a root node. If it's not a root node, then
  // that means that are multiple paths to that join, and we ignore it.
  const { rootNodes } = getJoinTreeForExplore({
    joins: cellContents.spec.joins,
    startingTable: maybeWhTable,
    tables: cellContents.spec.tables,
  });

  return rootNodes.find((node) => node.name === tableName)?.pathToNode;
};
